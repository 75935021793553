/**
 * Returns whether the time difference between the two timestamps is less than 12 hours
 *
 * @export
 * @param {number} timestamp1
 * @param {number} timestamp2
 * @returns {boolean}
 */
export function isTimeDifferenceLessThan12Hours(timestamp1: number, timestamp2: number): boolean {
  const millisecondsPerHour = 60 * 60 * 1000; // Number of milliseconds in an hour
  const maxTimeDifference = 12 * millisecondsPerHour; // 12 hours in milliseconds

  const difference = Math.abs(timestamp1 - timestamp2);

  return difference < maxTimeDifference;
}
