import { STORAGE_KEY_TO_BE_CLEARED } from './constants/StorageKeyList';

class Storage {
  storage = localStorage;

  set = (key: string, value: string) => {
    this.storage.setItem(key, value);
  };

  get = (key: string) => {
    return this.storage.getItem(key);
  };

  remove = (key: string) => {
    this.storage.removeItem(key);
  };

  clear = () => {
    STORAGE_KEY_TO_BE_CLEARED.forEach(key => {
      this.storage.removeItem(key);
    });
    window.dispatchEvent(new Event('storage-cleared'));
  };
}

export default Storage;
