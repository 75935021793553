import { Progress } from '@attentive-platform/stem-ui';

const AttentiveLoader = () => {
  return (
    <div className='flex justify-center align-center flex-column' style={{ width: '100%', height: '100%' }}>
      <img alt='loader' src='/img/aai-logo-dark-initials.svg' width='140' height='140' className='mb' />
      <Progress type='linear' width='160px' color='secondary' />
    </div>
  );
};

export default AttentiveLoader;
