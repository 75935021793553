export const CURRENT_DOMAIN_WITH_PROTOCOL = `${window.location.protocol}//${window.location.host}`;
export const AUTH_SERVICE_CALLBACKS = {
  AUTH_CALLBACK: '/auth-callback',
  LOGIN: '/login'
};

export enum ProfileOptions {
  Logout = 1
}

export enum HelpOptions {
  VideoGuides = 1,
  RaiseATicket = 2
}

export const ENTER_KEYCODE = 13;

export const HOME_PAGE_URL = '/';

export const CLIENT_TESTIMONIALS = [
  {
    heading: '"Best-in-class technology"',
    user: {
      logo: '/img/client-images/michael_wood_uslawns.webp',
      isPersonImage: true,
      name: 'Michael Wood',
      position: 'Director of Project Management, US Lawns'
    },
    comment:
      'Our partnership with Attentive aligns perfectly with our long-standing commitment to provide our Franchisees with best-in-class resources, technology, tools and training.'
  },
  {
    heading: '“Measurements are spot on”',
    user: {
      logo: '/img/client-images/mark_michalek_juniper.webp',
      isPersonImage: true,
      name: 'Mark Michalek',
      position: 'Director of Maintenance Estimating, Juniper'
    },
    comment:
      "Attentive is saving a lot of time for our team. The software is able to measure sites for us quickly and the measurements are spot on. It's made our sales process highly efficient."
  },
  {
    heading: '“Continuous flow of opportunities into the sales funnel”',
    user: {
      logo: '/img/client-images/bill_hope_beary.webp',
      isPersonImage: true,
      name: 'Bill Hope',
      position: 'Business Development, Beary Landscaping'
    },
    comment:
      'Attentive has been extremely helpful in providing my team and me with expedient & accurate estimates. It has also allowed us to apply a more continuous flow of opportunities into the sales funnel.'
  },
  {
    heading: '“Help us refine our bidding process”',
    user: {
      logo: '/img/client-images/daniel_leuenberger_denver.webp',
      isPersonImage: true,
      name: 'Daniel Leuenberger',
      position: 'General Manager of Landscaping, Denver Commercial Property Services'
    },
    comment:
      'Attentive is very cost-effective & the detailed reports help us refine our bidding process. We don’t have to worry about measurements at all anymore.'
  },
  {
    heading: '“Very strong documented measurements”',
    user: {
      logo: '/img/client-images/brian-steele-director-of-operations-east-coast-facilities.webp',
      isPersonImage: true,
      name: 'Brian Steel',
      position: 'Director of Operations, East Coast Facilities'
    },
    comment:
      'I am really confident about the Attentive software & its accuracy. We get very strong documented measurements and the margin of error is extremely low.'
  },
  {
    heading: '“Saved us countless hours”',
    user: {
      logo: '/img/client-images/daniel-ware-assistant-branch-mgr-duval-ls-mngt.webp',
      isPersonImage: true,
      name: 'Daniel Ware',
      position: 'Assistant Branch Manager, Duval Landscape Maintenance'
    },
    comment: 'The software has saved us countless hours of measuring while providing more accurate estimates.'
  },
  {
    heading: '“Very easy to use”',
    user: {
      logo: '/img/client-images/Christian_Oyer.webp',
      isPersonImage: true,
      name: 'Christian Oyer',
      position: 'Lead Estimator, Crimson Valley'
    },
    comment:
      'It’s very easy to use and saves time in gathering data on the properties. The detailed reports do a great job at breaking down where the measurements came from.'
  },
  {
    heading: '“Reports are detailed”',
    user: {
      logo: '/img/client-images/carruthers-landscaping.jpeg',
      isPersonImage: false,
      name: 'Ben Carruthers',
      position: 'President at Carruthers Landscape Management'
    },
    height: 'unset',
    comment:
      'We recently measured 232 properties with Attentive. The software is very smooth and we are impressed with it. The reports are detailed and we really enjoy working with them.'
  },
  // {
  //   heading: '“Terrific service”',
  //   user: {
  //     logo: '/images/home/Alpha Organics.jpeg',
  //     isPersonImage: false,
  //     name: 'Ken Rumps',
  //     position: ' Marketing Coordinator, Alpha Organics'
  //   },
  //   height: 'unset',
  //   comment:
  //     'We’ve been impressed by the software, its accuracy, and ease of use, and most of all by the terrific service provided by Attentive. The product has been truly beneficial to Alpha Organics.'
  // },
  {
    heading: '“Great accuracy”',
    user: {
      logo: '/img/client-images/Distefano_landscape.jpeg',
      isPersonImage: false,
      name: 'Don Distefano',
      position: 'President at Distefano Landscape Services Inc'
    },
    height: 'unset',
    comment:
      "With Attentive, I don't have to waste time on measurements at all. The software takes care of the measurements while also maintaining great accuracy."
  }
];

export const BASE_APP_TEST_IDENTIFIER = '$BASE-APP';

export const AUTH0_ERROR_CODES = {
  INVALID_REQUEST: 'invalid_request',
  UNAUTHORIZED_CLIENT: 'unauthorized_client',
  UNSUPPORTED_CREDENTIAL_TYPE: 'unsupported_credential_type',
  REQUEST_ERROR: 'request_error',
  ACCESS_DENIED: 'access_denied',
  BLOCKED_USER: 'blocked_user',
  TOO_MANY_ATTEMPTS: 'too_many_attempts'
};

export const TOAST_CONFIG = {
  position: 'top-center',
  hideProgressBar: false
} as const;

export const USER_ROLE_NAMES = {
  CREW_LEADER: 'crew leader',
  CREW_MEMBER: 'crew member'
};
