export const STORAGE_KEY_TO_BE_CLEARED = [
  // BASE
  'token',
  'userEmail',
  'userSub',
  'last-filters-store',

  // third party
  '___ug___',
  'persist:root',

  // POD2
  'estimation_table_first_column_width',
  'change_order_add_table_first_column_width',
  'estimateDetailsHiddenColumns',
  'serviceDetailsHiddenColumns',
  'estimateDetailsColumnsOrder',
  'serviceDetailsColumnsOrder',
  'service_detail_table_first_column_width',
  'maintenanceEstimateDetailsHiddenColumns',
  'maintenanceServiceDetailsHiddenColumns',
  'maintenanceEstimateDetailsColumnsOrder',
  'maintenanceServiceDetailsColumnsOrder',
  'COHiddenCols',
  'COColsOrder',
  'BulkDetailHiddenCols',
  'BulkDetailColsOrder',

  // POD3
  'ops_branch'
];
