import { QueryClient } from 'react-query';

export * from './ApiCalls';
export * from './AppBase';
export * from './stringUtils';
export * from './helpers';
export * from './constants/constants';
export * as DEFAULT_VALUES from './defaults';
export { default as customEvent } from './customEvents';
export { default as IndexedDB } from './IndexedDB';
export { default as Storage } from './Storage';
export * as DateUtils from './Date';
export { default as debounce } from './debounce';
export { default as useValidateInput } from './hooks/useValidateInput';
export * from './types/index';
export { default as useQueryParamFilter } from './hooks/useQueryParamFilter';
export { default as useQueryFilters } from './hooks/useQueryFilters';
export { default as useDebounce } from './hooks/useDebounce';
export { default as useReferrer } from './hooks/useReferrer';
export { default as useToast } from './hooks/useToast';
export { default as useReactRouter } from './hooks/useReactRouter';
export { searchInEveryLevel } from './search';
export { default as useOpenClose } from './hooks/useOpenClose';
export * from './filterUtils';
export { default as useWebWorker } from './hooks/useWebWorker';
export { handleSortData, handleFilterData } from './workers/operation.worker';
export * from './error-handling/decorateAPIError';
export * from './eventManager';
export const queryClient = new QueryClient();
export { default as useListVirtualization } from './hooks/useListVirtualization';
export { default as useRememberScroll } from './hooks/useRememberScroll';

/* Revamped shared-utils */
export * from './utils/date';
export * from './utils/number';
export * from './utils/string';
export * from './utils/array';
export * from './utils/object';
export * from './utils/currency';
