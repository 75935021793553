import { PostHog } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

const PosthogProvider = ({
  client,
  children,
  isProduction
}: {
  client: PostHog;
  children: any;
  isProduction: boolean;
}) => {
  if (isProduction) {
    <PostHogProvider client={client}>{children}</PostHogProvider>;
  }
  return children;
};

export default PosthogProvider;
