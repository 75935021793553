import auth0 from 'auth0-js';
import { useAuthStore } from 'shared-store';
import { queryClient, storage } from 'shared-utils';
import { isTimeDifferenceLessThan12Hours } from '../utils/helpers';
import { CURRENT_DOMAIN_WITH_PROTOCOL, AUTH_SERVICE_CALLBACKS } from '../constants/constants';

type LoginParams = {
  email: string;
  password: string;
  returnToUrl: string | null;
  onSettled: (...args: any[]) => void;
};

class AuthService {
  checkSessionInProgress = false;

  logoutInProgress = false;

  lastRefreshTokenTimeStamp: number | null = null;

  auth0 = new auth0.WebAuth({
    domain: process.env.AUTH0_DOMAIN,
    clientID: process.env.AUTH0_CLIENT_ID,
    responseMode: 'fragment',
    responseType: 'token id_token',
    audience: process.env.AUTH0_AUDIENCE
  });

  auth0Manage = null;

  login = ({ email, password, returnToUrl, onSettled = () => {} }: LoginParams) => {
    this.auth0.login(
      {
        email,
        password,
        realm: process.env.AUTH0_CONNECTION,
        grantType: 'password',
        redirectUri: `${CURRENT_DOMAIN_WITH_PROTOCOL}${AUTH_SERVICE_CALLBACKS.AUTH_CALLBACK}${
          returnToUrl ? `?return_to=${encodeURIComponent(returnToUrl)}` : ''
        }`,
        scope: 'openid profile email offline_access'
      },
      onSettled
    );
  };

  logout = (clearTokenInStore = () => {}, isManualLogout = false) => {
    if (!this.logoutInProgress) {
      this.logoutInProgress = true;
      const returnToUrlComponent = isManualLogout
        ? ''
        : `?return_to=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`;
      this.auth0.logout({
        returnTo: `${CURRENT_DOMAIN_WITH_PROTOCOL}${AUTH_SERVICE_CALLBACKS.LOGIN}${returnToUrlComponent}`
      });
      clearTokenInStore();
    }
  };

  // Edge case: If refresh token call was successful still checkSession is called again,
  // then this is mostly because of wrong handling on backend or Auth0.
  // In this case, to avoid running into infinite loop of refresh token -> API 401 -> refresh token,
  // we will log the user out.
  verifyRepeatedRefreshCall = () => {
    let isRepeatedRefreshCall = false;
    const currentTimestamp = new Date().valueOf();
    if (
      this.lastRefreshTokenTimeStamp &&
      isTimeDifferenceLessThan12Hours(this.lastRefreshTokenTimeStamp, currentTimestamp)
    ) {
      this.logout(() => {
        storage.clear();
      });
      isRepeatedRefreshCall = true;
    }
    return isRepeatedRefreshCall;
  };

  checkSession = (returnToUrl = `${window.location.pathname}${window.location.search}`) => {
    const isRepeatedRefreshCall = this.verifyRepeatedRefreshCall();

    if (!this.checkSessionInProgress && !isRepeatedRefreshCall) {
      useAuthStore.setState({ isRefreshingTokenAndRefetchingQueries: true });
      this.checkSessionInProgress = true;

      this.auth0.checkSession(
        {
          realm: process.env.AUTH0_CONNECTION,
          grantType: 'password',
          redirectUri: `${CURRENT_DOMAIN_WITH_PROTOCOL}${AUTH_SERVICE_CALLBACKS.AUTH_CALLBACK}${
            returnToUrl ? `?return_to=${encodeURIComponent(returnToUrl)}` : ''
          }`
        },
        (err, authResult) => {
          this.checkSessionInProgress = false;
          if (err) {
            useAuthStore.setState({ isRefreshingTokenAndRefetchingQueries: false });
            this.logout(() => {
              storage.clear();
            });
            return;
          }
          this.lastRefreshTokenTimeStamp = new Date().valueOf();
          storage.set('token', authResult.accessToken);
          // TODO: Sometimes it did not refetch all queries
          queryClient.refetchQueries().finally(() => {
            useAuthStore.setState({ isRefreshingTokenAndRefetchingQueries: false });
          });
        }
      );
    }
  };

  parseAuthHash = (hashToParse: any, onSuccess: (...args: any[]) => any) => {
    this.auth0.parseHash({ hash: hashToParse }, (error: any, authResult: any) => {
      if (error) return;
      this.auth0.client.userInfo(authResult.accessToken, (userInfoError: any, user: any) => {
        if (userInfoError) return;
        onSuccess(authResult, user);
      });
    });
  };
}

export default AuthService;
