import { lazy, useEffect, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import axios, { AxiosError } from 'axios';
import posthog from 'posthog-js';
import { QueryClientProvider } from 'react-query';
import { queryClient, storage } from 'shared-utils';
import {
  BrowserRouter as Router,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { auth } from './utils/auth';
import './styles/style.less';
import PosthogProvider from './wrappers/PosthogProvider';
import AttentiveLoader from './shared/Loaders/AttentiveLoader';

const App = lazy(() => import('./App'));

const container = document.getElementById('root') as Element;
const root = createRoot(container);

const isProduction = process.env.APP_ENV === 'prod';

if (isProduction && process.env.POSTHOG_KEY) {
  posthog.init(process.env.POSTHOG_KEY, {
    persistence: 'localStorage',
    enable_recording_console_log: true
  } as const);
}

if (isProduction) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    replaysSessionSampleRate: 0.3, // 30% of sessions will be replayed
    replaysOnErrorSampleRate: 1.0, // 100% of errors in the sampled sessions will be replayed
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      posthog.sentryIntegration({
        organization: process.env.SENTRY_ORGANIZATION_SLUG,
        projectId: Number(process.env.SENTRY_PROJECT_ID)
      }),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration()
    ],
    tracePropagationTargets: ['web.attentive.ai'],
    tracesSampler: samplingContext => {
      if (samplingContext?.attributes?.['sentry.op'] === 'http.client') {
        // We do not want to capture API calls' traces
        return 0;
      }
      // Default sample rate for other transactions
      return 0.5;
    },
    profilesSampleRate: 1.0,
    environment: 'prod',
    release: process.env.SENTRY_RELEASE
  });

  const email = storage.get('userEmail');
  if (email) {
    const scope = Sentry.getCurrentScope();
    scope.setUser({
      email
    });
  }
}

axios.interceptors.response.use(
  response => response,
  error => {
    const errorStatus = error?.response?.status;
    if (errorStatus >= 400 && errorStatus < 600 && ![401, 403].includes(errorStatus)) {
      const xRequestId = error?.response?.config?.headers['x-request-id'];
      const requestData = error?.config?.data;

      Sentry.setExtras({
        status: errorStatus,
        code: error?.code,
        message: error?.message,
        name: error?.name,
        xRequestId,
        requestBody: requestData instanceof FormData ? Object.fromEntries(requestData) : requestData,
        errorInstance: error instanceof AxiosError ? JSON.parse(JSON.stringify(error)) : null,
        apiResponseData: JSON.stringify(error?.response?.data),
        frontendRoute: window.location.href,
        apiEndpoint: error?.config?.url
      });
      Sentry.setExtras(error);
      Sentry.captureException(
        new Error(
          `Request failure with status code ${errorStatus} | XRI ${xRequestId} | API Endpoint ${error?.config?.url}`
        )
      );
    }
    if (errorStatus === 401) {
      auth.checkSession();
      return error;
    }
    return Promise.reject(error);
  }
);

root.render(
  <PosthogProvider client={posthog} isProduction={isProduction}>
    <Router>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<AttentiveLoader />}>
          <App />
        </Suspense>
      </QueryClientProvider>
    </Router>
  </PosthogProvider>
);
