import create from 'zustand';

interface IAuth {
  name?: string;
}

interface IAuthState {
  user: IAuth | null;
  isLoggedIn: boolean;
  isRefreshingTokenAndRefetchingQueries: boolean;
  updateState: (...args: any[]) => any;
  clearState: (...args: any[]) => any;
}

const token = localStorage.getItem('token');

const initialState = {
  user: null,
  isLoggedIn: Boolean(token),
  isRefreshingTokenAndRefetchingQueries: false
};

const useAuthStore = create<IAuthState>()(set => ({
  ...initialState,
  updateState: updatedState => set(state => ({ ...state, ...updatedState })),
  clearState: () => set(state => ({ ...state, ...initialState }))
}));

export default useAuthStore;
